import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { AnnouncementsContext } from '../../../contexts/AnnouncementsContext';
import clsx from 'clsx';
import ReactHtmlParser from 'react-html-parser';

const AnnouncementItem = ({ propsAnnouncement = null, announcementView = null }) => {
  const { announcements, handleClose } = useContext(AnnouncementsContext);
  const htmlParser = ReactHtmlParser;
  const announcementsList = propsAnnouncement ? propsAnnouncement : announcements;
  const [maxHeight, setMaxHeight] = useState(Math.round(window.innerHeight - 300));

  useEffect(() => {
    const handleResize = () => setMaxHeight(Math.round(window.innerHeight - 300));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const lastObjectPlacement =
    announcementsList.length > 0 ? announcementsList[announcementsList.length - 1].placement : null;

  return (
    lastObjectPlacement && (
      <div
        className={clsx(!propsAnnouncement && 'position-absolute w-100 p-4')}
        style={{
          ...(lastObjectPlacement === 'top-left' ||
          lastObjectPlacement === 'top-middle' ||
          lastObjectPlacement === 'top-right'
            ? { top: '70px' }
            : lastObjectPlacement === 'bottom-left' ||
              lastObjectPlacement === 'bottom-middle' ||
              lastObjectPlacement === 'bottom-right'
            ? { bottom: '-85vh' }
            : {}),
          ...(announcementView && announcementView.placement === 'top-left' ? { paddingBottom: '100px' } : {}),
          ...(announcementView && announcementView.placement === 'top-middle' ? { paddingBottom: '100px' } : {}),
          ...(announcementView && announcementView.placement === 'top-right' ? { paddingBottom: '100px' } : {}),
          ...(announcementView && announcementView.placement === 'bottom-left' ? { paddingTop: '100px' } : {}),
          ...(announcementView && announcementView.placement === 'bottom-middle' ? { paddingTop: '100px' } : {}),
          ...(announcementView && announcementView.placement === 'bottom-right' ? { paddingTop: '100px' } : {}),
        }}
      >
        {announcementsList &&
          announcementsList.length > 0 &&
          announcementsList.map(announcement => (
            <div
              key={announcement.identifier}
              className={clsx([
                'd-flex w-100 mb-3',
                (announcementView ? announcementView.placement : announcement.placement).includes('left')
                  ? 'justify-content-start'
                  : (announcementView ? announcementView.placement : announcement.placement).includes('middle')
                  ? 'justify-content-center'
                  : (announcementView ? announcementView.placement : announcement.placement).includes('right')
                  ? 'justify-content-end'
                  : '',
              ])}
            >
              <div
                className={clsx(
                  'card basic-shadow p-2',
                  announcementView && 'width-' + announcementView.width,
                  !announcementView && 'width-' + announcement.width
                )}
              >
                <div className="card-body">
                  <h2 className="bold mt-2 mb-4">{announcement.headline}</h2>
                  <hr />
                  <div style={{ maxHeight: `${maxHeight}px` }} className="overflow-scroll">
                    {htmlParser(announcement.text)}
                  </div>
                </div>
                <div className="d-flex justify-content-end p-2">
                  <Button
                    className="btn-darkBlue"
                    onClick={() =>
                      !propsAnnouncement
                        ? handleClose(announcement.identifier)
                        : console.log(announcementView.placement)
                    }
                  >
                    {announcement.buttonText}
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
    )
  );
};

export default AnnouncementItem;
