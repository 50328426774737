import { userPermissions } from './constants';

export const handleComposeMenu = (
  permissions,
  featureFlags,
  tenantType,
  isDocumentsAcceptOverdue,
  isXelonChild = false,
  isWhiteLabeling = false
) => {
  const menu = [
    !isDocumentsAcceptOverdue && {
      title: null,
      foldable: false,
      links: [
        {
          path: '/',
          title: 'Dashboard',
          icons: 'fas fa-tachometer-alt',
          exact: true,
          id: 'home',
          isVisible: isWhiteLabeling ? true : 'dashboard' in featureFlags,
          accessPermissions: [],
        },
        {
          path: '/my-organization',
          title: 'Manage My Organization',
          icons: 'fas fa-suitcase',
          id: 'my-organization',
          isVisible: isWhiteLabeling ? true : 'manage-my-organization' in featureFlags,
          accessPermissions: ['allow_view_organizations'],
        },
        {
          path: '/all-organizations',
          title: 'Manage All Organizations',
          icons: 'fas fa-sitemap',
          id: 'all-organizations',
          isVisible: isWhiteLabeling ? true : 'manage-all-organizations' in featureFlags,
          accessPermissions: ['allow_view_organizations'],
        },
      ],
    },
    !isDocumentsAcceptOverdue && {
      title: 'Virtual Datacenter',
      foldable: false,
      links: [
        {
          path: '/all-devices',
          title: 'All Devices',
          icons: 'fas fa-server',
          id: 'allDevices',
          isVisible: isWhiteLabeling ? true : 'all-devices' in featureFlags,
          accessPermissions: ['allow_view_virtual_machines'],
        },
        {
          path: '/disaster-recovery',
          title: 'Disaster Recovery',
          icons: 'fas draas-icon',
          id: 'disasterRecovery',
          isVisible: isWhiteLabeling ? true : 'disaster-recovery' in featureFlags,
          accessPermissions: ['allow_manage_disaster_recovery'],
        },
        {
          path: '/templates-and-isos',
          title: 'Templates & ISOs',
          icons: 'fas fa-file-invoice',
          id: 'templates',
          isVisible: isWhiteLabeling ? true : 'templates-isos' in featureFlags,
          accessPermissions: ['allow_view_templates'],
        },
        {
          path: '/networking',
          title: 'Networking',
          icons: 'fas fa-vector-square',
          id: 'network',
          isVisible: isWhiteLabeling ? true : 'networking' in featureFlags,
          accessPermissions: ['allow_view_networking'],
        },
      ],
    },
    process.env.REACT_APP_WHITELABELING !== 'true' &&
      !isDocumentsAcceptOverdue && {
        title: 'Kubernetes',
        foldable: false,
        links: [
          {
            path: '/kubernetes',
            title: 'Kubernetes',
            icons: 'fas fa-dharmachakra',
            id: 'Kubernetes',
            isVisible: true,
            accessPermissions: ['allow_access_beta_features'],
          },
          {
            path: '/kubernetes-talos',
            title: 'Kubernetes ',
            icons: 'fas fa-dharmachakra',
            id: 'Kubernetes-talos',
            isVisible: isWhiteLabeling ? true : 'kubernetes' in featureFlags,
            accessPermissions: ['allow_manage_kubernetes'],
          },
          {
            path: '/persistent-storage',
            title: 'Persistent Storage',
            icons: 'fas fa-compact-disc',
            id: 'persistent-storage',
            isVisible: isWhiteLabeling ? true : 'persistent-storage' in featureFlags,
            accessPermissions: ['allow_access_beta_features'],
          },
          /*{
          path: '/databases',
          title: 'Databases',
          icons: 'fas fa-database',
          id: 'Databases',
          isVisible: true,
          accessPermissions: [],
        },*/
        ],
      },
    !isDocumentsAcceptOverdue && {
      title: 'Billing & Reports',
      foldable: true,
      links: [
        {
          path: '/billing-details',
          title: 'Billing Details',
          icons: 'fas fa-credit-card',
          id: 'billingdetails',
          isVisible: isWhiteLabeling ? true : 'billing-details' in featureFlags,
          accessPermissions: [],
        },
        {
          path: '/usage-reports',
          title: 'Usage Reports',
          icons: 'fas fa-chart-pie',
          id: 'vdc',
          isVisible: isWhiteLabeling ? true : 'usage-reports' in featureFlags,
          accessPermissions: ['allow_view_usage_overview'],
        },
      ],
    },
    !isDocumentsAcceptOverdue && {
      title: 'Xelon Root User',
      foldable: true,
      links: [
        {
          path: '/operational-area',
          title: 'Operational Area',
          icons: 'fas fa-wrench',
          id: 'operationalArea',
          isVisible: true,
          accessPermissions: ['allow_manage_operations'],
        },
        {
          path: '/sales-dashboard/legal-docs',
          title: 'Sales Dashboard',
          icons: 'fas fa-chart-line',
          id: 'sales-dashboard',
          isVisible: true,
          accessPermissions: ['allow_manage_sales_dashboard'],
        },
        {
          path: '/accounting',
          title: 'Accounting',
          icons: 'far fa-address-book',
          id: 'accounting',
          isVisible: true,
          accessPermissions: ['allow_manage_invoices'],
        },
      ],
    },
    !isDocumentsAcceptOverdue && {
      title: 'Admin panel',
      foldable: true,
      links: [
        {
          path: '/announcements',
          title: 'Announcements',
          icons: 'far fa-comment-alt',
          id: 'announcements',
          isVisible: isWhiteLabeling ? true : 'announcements' in featureFlags,
          accessPermissions: ['allow_manage_announcements'],
        },
      ],
    },
    isXelonChild && {
      title: 'Documents',
      foldable: true,
      links: [
        {
          path: '/documents',
          title: 'Legal docs',
          icons: 'far fa-file',
          id: 'documents',
          isVisible: true,
          accessPermissions: ['allow_view_documents'],
        },
      ],
    },
  ].filter(el => el);

  let newMenu = [];
  let showBillingDetails = true;
  if (
    !permissions.includes('allow_manage_payment_methods') &&
    !permissions.includes('allow_view_organization_invoices') &&
    !permissions.includes('allow_view_credits_info') &&
    !permissions.includes('allow_manage_billing_plans')
  ) {
    showBillingDetails = false;
  }

  for (let i = 0; i < menu.length; i++) {
    let links = [];
    for (let j = 0; j < menu[i].links.length; j++) {
      if (menu[i].links[j].accessPermissions.length !== 0) {
        if (permissions.includes(menu[i].links[j].accessPermissions[0])) {
          links = links.concat(menu[i].links[j]);
        }
        if (menu[i].links[j].title === 'Manage All Organizations' && tenantType === 2) {
          links = links.filter(link => link.title !== 'Manage All Organizations');
        }
      } else if (menu[i].links[j].title === 'Billing Details' && !showBillingDetails) {
        links = links.filter(link => link.title !== 'Billing Details');
      } else links = links.concat(menu[i].links[j]);
    }
    const menuObject = menu[i];
    menuObject.links = links;
    if (links.length > 0) {
      newMenu = newMenu.concat(menuObject);
    }
  }

  return newMenu;
};

export const handleConvertPermissions = permissions => {
  for (const property in userPermissions) {
    userPermissions[property] = false;
  }
  if (permissions.length > 0) {
    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i]
        .split('_')
        .map((el, key) => {
          if (key > 0) {
            return el.charAt(0).toUpperCase() + el.slice(1);
          }
          return el;
        })
        .join('');
      if (userPermissions[permission] === false) {
        userPermissions[permission] = true;
      }
    }
    return userPermissions;
  }

  return userPermissions;
};

export const setAuthInitialState = () => {
  let initialState = {
    isAuth: false,
    allowCreatingTrial: false,
    user: {},
    menu: [],
    token: '',
    dashboardWidget: '',
    loggedInAsOtherUser: false,
    rootUser: {},
    isRoot: false,
    tenant: '',
    demoTenant: false,
    onboardingCompleted: undefined,
    tenantType: '',
    parent: '',
    userPermissions: {},
  };

  const localAppState = localStorage['appState'] ? JSON.parse(localStorage['appState']) : null;

  if (localStorage['token']) {
    if (localAppState) localAppState.token = localStorage['token'];
  }

  if (localStorage['root']) {
    if (localAppState) localAppState.loggedInAsOtherUser = true;
  }

  if (localAppState) {
    initialState = localAppState;
  }

  return initialState;
};
