import React, { useState } from 'react';
import { Card } from 'reactstrap';
import clsx from 'clsx';
import { handleDateObjectConversion } from '../../utils/timeConversion';

const Notification = ({ className, data, id, read_at, created_at, onRead }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <Card
      className={clsx(
        'd-flex flex-row p-3 position-relative justify-content-between h-100 w-100',
        read_at && 'bg-grey',
        'notification-card',
        isActive && 'notification-card-active',
        className
      )}
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
        if (!read_at) {
          onRead(id);
        }
      }}
    >
      {!read_at && (
        <div
          className="bg-success position-absolute "
          style={{
            top: '1.2em',
            left: '-0.5em',
            height: '1em',
            width: '1em',
            borderRadius: '50%',
          }}
        />
      )}
      <div className="d-flex flex-column notification-card-text">
        <div>{data.name}</div>
        <div className="font-gray font-italic mt-3">{data.description}</div>
      </div>
      <div className="notification-card-date">{handleDateObjectConversion(created_at)}</div>
    </Card>
  );
};

export default Notification;
