import React from 'react';
import { Button } from 'reactstrap';
import ButtonWithDeleteModal from '../../UI/ButtonWithDeleteModal';

const Pool = ({
  pool,
  clusterIdentifier,
  poolIndex,
  toggleCollapse,
  setEditPoolModalIsOpen,
  setEditedPool,
  setAddNodeModalIsOpen,
  fetchClusterPoolsNodes,
}) => {
  return (
    <div key={`pool-${poolIndex}`}>
      <div className="hierarchy pr-3 pl-4 py-2">
        <Button
          className="btn-expand text-primary border-0"
          color=""
          onClick={e => toggleCollapse(e)}
          data-open="false"
        >
          <i className="fas fa-chevron-circle-down" />
        </Button>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span>{pool.name}</span> {pool.has_extra_storage ? <span>(with extra storage)</span> : ''}
          </div>
          <div className="mr-2">
            <Button
              color="light"
              data-cy="pools-and-nodes-alert-settings"
              className="mr-3 py-2 px-3"
              style={{ transform: 'rotate(0deg)' }}
              disabled={pool.status === 'Changing resources'}
              onClick={() => {
                setAddNodeModalIsOpen(true);
                setEditedPool(pool);
              }}
            >
              <i className="fas fa-plus" />
            </Button>
            <Button
              color="light"
              data-cy="pools-and-nodes-alert-settings"
              className="mr-3 py-2 px-3"
              style={{ transform: 'rotate(0deg)' }}
              disabled={pool.status === 'Changing resources'}
              onClick={() => {
                setEditPoolModalIsOpen(true);
                setEditedPool(pool);
              }}
            >
              <i className="fas fa-cog" />
            </Button>
            <ButtonWithDeleteModal
              key={`delete-pool_${poolIndex}`}
              dataCy="delete-pool"
              url={`api/user/kubernetes-talos/${clusterIdentifier}/delete-pool/${pool.identifier}`}
              entity="Delete pool"
              className="btn-sm btn-pink"
              style={{ transform: 'rotate(0deg)', minWidth: '92px' }}
              onDeleteFinished={() => fetchClusterPoolsNodes()}
              disabled={pool.status === 'Changing resources'}
              name="Worker node"
            >
              <span>Delete</span>
            </ButtonWithDeleteModal>
          </div>
        </div>
      </div>
      <div className="hierarchy-children pl-4">
        <div className="hierarchy-items">
          {pool.nodes.map((node, index) => (
            <div
              key={`${index}-${node.name}`}
              className="pr-3 pl-4 py-2 border-bottom d-flex justify-content-between align-items-center"
            >
              <div>
                {node.status === 'Deleting' && <span className="text-danger mr-2">[Deleting]</span>}
                {node.name}
              </div>
              {node.status === 'Changing resources' ? (
                <div className="d-flex mr-2">
                  <div>
                    <i className="mr-2 fa fa-refresh rotating" style={{ fontSize: '10px' }} />
                    Updating resources
                  </div>
                </div>
              ) : (
                node.status !== 'Deleting' && (
                  <ButtonWithDeleteModal
                    dataCy="destroy-talos-node"
                    url={`api/user/kubernetes-talos/${clusterIdentifier}/delete-node/${node.identifier}`}
                    entity="Delete node"
                    className="btn-sm btn-pink mr-2"
                    style={{ minWidth: '92px' }}
                    onDeleteFinished={() => fetchClusterPoolsNodes()}
                    name="Worker Node"
                  >
                    <span>Delete</span>
                  </ButtonWithDeleteModal>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pool;
