import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, Card, CardBody, FormFeedback } from 'reactstrap';
import queryString from 'query-string';

import { AuthContext } from '../contexts/AuthContext';
import Form from '../UI/Form';
import { thirdPartyReqData } from '../../utils/constants';
import GoogleLogo from '../../images/google-logo.svg';
import GitHubLogo from '../../images/github-logo.svg';

const LoginForm = props => {
  const [loginData, setData] = useState({
    email: '',
    password: '',
    agreement: false,
    remember: true,
  });

  const { login, thirdPartyLogin, needAcceptAgreement } = useContext(AuthContext);

  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMeCheckbox');
    if (!rememberMeValue) {
      localStorage.setItem('rememberMeCheckbox', loginData.remember);
    } else {
      setData({
        ...loginData,
        remember: rememberMeValue === 'true',
      });
    }

    processPromocodeParamsQuery();
    let thirdPartyProvider = null;
    if (queryString.parse(props.history.location.search).githubAuth) {
      thirdPartyProvider = 'github';
    }
    // for Google auth via redirect
    else if (window.location.href.indexOf('code') >= 0) {
      thirdPartyProvider = 'google';
    }

    if (thirdPartyProvider) {
      const query = queryString.parse(props.history.location.search);
      if (query.state) {
        localStorage.setItem('promocode', query.state);
      }
      thirdPartyLogin(thirdPartyProvider, query.code);
    }
  }, []);

  const processPromocodeParamsQuery = () => {
    const allowedProviders = ['google', 'github'];
    const query = queryString.parse(props.history.location.search);

    if (allowedProviders.includes(query.provider_name)) {
      setTimeout(() => document.getElementById(`${query.provider_name}-button`).click(), 1000);
    }

    if (query.promo) {
      localStorage.setItem('promocode', query.promo);
    }
  };

  const handleInputChange = event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setData({
      ...loginData,
      [event.target.name]: value,
    });

    if (event.target.name === 'remember') {
      localStorage.setItem('rememberMeCheckbox', value);
    }
  };

  return (
    <>
      <Card className="login">
        <CardBody>
          <Form submit={() => login(loginData, props, props.setNotificationConnection)}>
            <p>Log in to start your session</p>
            <InputGroup>
              <Input
                data-cy="email-input"
                type="email"
                name="email"
                placeholder="Email"
                value={loginData.email}
                onChange={handleInputChange}
                required
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="fas fa-envelope" />
                </InputGroupText>
              </InputGroupAddon>
              <FormFeedback />
            </InputGroup>
            <InputGroup>
              <Input
                data-cy="password-input"
                type="password"
                name="password"
                placeholder="Password"
                value={loginData.password}
                onChange={handleInputChange}
                required
                minLength={6}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="fas fa-lock" />
                </InputGroupText>
              </InputGroupAddon>
              <FormFeedback />
            </InputGroup>
            {needAcceptAgreement && (
              <div
                className="form-check form-check-inline mt-2 mb-1"
                style={{
                  animation: !loginData.agreement ? 'wiggle 4s linear infinite' : 'none',
                }}
              >
                <input
                  data-cy="accept-agreement-input"
                  className="form-check-input"
                  type="checkbox"
                  id="accept-agreement"
                  name="agreement"
                  checked={loginData.agreement}
                  onChange={handleInputChange}
                />
                <label className="form-check-label color-black" htmlFor="accept-agreement">
                  I agree to{' '}
                  <a
                    target="_blank"
                    href="http://www.xelon.ch/terms-and-conditions"
                    rel="noreferrer"
                    className="color-primary"
                  >
                    T&C
                  </a>
                  ,{' '}
                  <a
                    target="_blank"
                    href="http://www.xelon.ch/en/technical-and-organizational-measures"
                    rel="noreferrer"
                    className="color-primary"
                  >
                    TOM
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    href="http://www.xelon.ch/en/agreement-on-the-processing-of-order-data"
                    rel="noreferrer"
                    className="color-primary"
                  >
                    ADV
                  </a>
                </label>
              </div>
            )}
            <Button
              data-cy="login-button"
              className="w-100"
              color="primary"
              disabled={needAcceptAgreement && !loginData.agreement}
            >
              Log in
            </Button>
            <div className="d-flex justify-content-between pt-2">
              <div className="form-check form-check-inline">
                <div className="d-flex">
                  <input
                    data-cy="remember-me-input"
                    className="form-check-input"
                    type="checkbox"
                    id="remember-me"
                    name="remember"
                    checked={loginData.remember}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label" htmlFor="remember-me">
                    Remember me
                  </label>
                </div>
              </div>
              <Link data-cy="forgot-password" className="text-dark" to="login/password-reset">
                Forgot your password?
              </Link>
            </div>
          </Form>
        </CardBody>
        {process.env.REACT_APP_WHITELABELING !== 'true' && (
          <div className="d-flex px-0">
            <Button
              id="google-button"
              data-cy="google-sign-in"
              onClick={() =>
                window.open(
                  `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email+profile&include_granted_scopes=true&response_type=code&redirect_uri=${window.location.origin}/login&client_id=${thirdPartyReqData.client_id_google}`,
                  '_self'
                )
              }
              className="sign-in__container"
            >
              <img className="sign-in__container__google-logo img-night" src={GoogleLogo} alt="google-logo" />
            </Button>
            <Button
              id="github-button"
              data-cy="github-sign-in"
              onClick={() =>
                window.open(
                  `https://github.com/login/oauth/authorize?scope=user&client_id=${thirdPartyReqData.client_id_git}&redirect_uri=${window.location.origin}/login?githubAuth=true`,
                  '_self'
                )
              }
              className="sign-in__container github-container"
            >
              <img className="sign-in__container__github-logo img-night" src={GitHubLogo} alt="github-logo" />
            </Button>
          </div>
        )}
      </Card>
    </>
  );
};

export default LoginForm;
