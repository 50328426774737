import React, { useEffect, useState } from 'react';
import ControlPlanes from './ControlPlanes';
import PoolsAndNodes from './PoolsAndNodes';
import { Button, Card, CardBody } from 'reactstrap';
import AddPoolModal from './AddPoolModal';
import EditPoolModal from './EditPoolModal';
import AddNodeModal from './AddNodeModal';
import EditControlPlaneModal from './EditControlPlaneModal';

const ClusterItems = ({
  clusterIdentifier,
  clusterControlPlanes,
  clusterInfo,
  fetchingClusterControlPlanes,
  clusterPoolsNodes,
  fetchingClusterPoolsNodes,
  fetchClusterPoolsNodes,
  fetchPoolCpResources,
  clusterMode,
  addPool,
  setIsOpenUpgradeModal,
  isUpgrading,
  clusterControlResources,
}) => {
  const [addPoolModalIsOpen, setAddPoolModalIsOpen] = useState(false);
  const [editPoolModalIsOpen, setEditPoolModalIsOpen] = useState(false);
  const [editedPool, setEditedPool] = useState({});
  const [addNodeModalIsOpen, setAddNodeModalIsOpen] = useState(false);
  const [editControlPlaneModalIsOpen, setEditControlPlaneModalIsOpen] = useState(false);
  const [controlPlanesUpdating, setControlPlanesUpdating] = useState(null);

  useEffect(() => {
    if (clusterInfo.clusterStatus) {
      setControlPlanesUpdating(clusterInfo.clusterStatus === 'Updating control planes');
    }
  }, [clusterInfo]);

  const toggleCollapse = e => {
    const element = e.target.parentNode.getAttribute('data-open') ? e.target.parentNode : e.target;
    element.getAttribute('data-open') === 'false'
      ? element.setAttribute('data-open', 'true')
      : element.setAttribute('data-open', 'false');

    element.parentNode.classList.toggle('shift');
  };

  return (
    <>
      <Card className="mb-4">
        <CardBody className="d-flex flex-row justify-content-between card-header-low-border">
          <h3 className="mb-0" style={{ fontSize: '1.125rem' }}>
            Pools and Nodes
          </h3>
          <div className="d-flex">
            <Button
              className="btn-sm"
              color="primary"
              style={{ minWidth: '92px' }}
              onClick={() => setAddPoolModalIsOpen(true)}
              data-cy="pools-and-nodes-add-service"
            >
              Add Pool
            </Button>
          </div>
        </CardBody>
        <CardBody className="px-0 py-0">
          <ControlPlanes
            clusterControlPlanes={clusterControlPlanes}
            clusterInfo={clusterInfo}
            fetchingClusterControlPlanes={fetchingClusterControlPlanes}
            toggleCollapse={toggleCollapse}
            clusterIdentifier={clusterIdentifier}
            clusterMode={clusterMode}
            setIsOpenUpgradeModal={setIsOpenUpgradeModal}
            setEditControlPlaneModalIsOpen={setEditControlPlaneModalIsOpen}
            controlPlanesUpdating={controlPlanesUpdating}
            isUpgrading={isUpgrading}
          />
          <PoolsAndNodes
            clusterIdentifier={clusterIdentifier}
            clusterPoolsNodes={clusterPoolsNodes}
            fetchingClusterPoolsNodes={fetchingClusterPoolsNodes}
            toggleCollapse={toggleCollapse}
            setEditPoolModalIsOpen={setEditPoolModalIsOpen}
            setAddNodeModalIsOpen={setAddNodeModalIsOpen}
            setEditedPool={setEditedPool}
            fetchClusterPoolsNodes={fetchClusterPoolsNodes}
          />
        </CardBody>
      </Card>
      {addPoolModalIsOpen && (
        <AddPoolModal
          modalOpen={addPoolModalIsOpen}
          addPool={addPool}
          toggleModal={() => {
            setAddPoolModalIsOpen(false);
          }}
        />
      )}
      {editPoolModalIsOpen && (
        <EditPoolModal
          editedPool={editedPool}
          clusterIdentifier={clusterIdentifier}
          minDiskSize={editedPool.disk}
          modalOpen={editPoolModalIsOpen}
          fetchPoolCpResources={fetchPoolCpResources}
          toggleModal={() => {
            setEditPoolModalIsOpen(false);
          }}
        />
      )}
      {editControlPlaneModalIsOpen && (
        <EditControlPlaneModal
          clusterIdentifier={clusterIdentifier}
          minDiskSize={editedPool.disk}
          modalOpen={editControlPlaneModalIsOpen}
          clusterControlPlanes={clusterControlPlanes}
          clusterControlResources={clusterControlResources}
          fetchPoolCpResources={fetchPoolCpResources}
          toggleModal={() => {
            setEditControlPlaneModalIsOpen(false);
          }}
          setControlPlanesUpdating={setControlPlanesUpdating}
        />
      )}
      {addNodeModalIsOpen && (
        <AddNodeModal
          editedPoolIdentifier={editedPool.identifier}
          editedPool={editedPool}
          clusterIdentifier={clusterIdentifier}
          addNodeModalIsOpen={addNodeModalIsOpen}
          fetchPoolCpResources={fetchPoolCpResources}
          toggleModal={() => {
            setAddNodeModalIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ClusterItems;
