import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'reactstrap';
import axios from '../../../axiosInstance';
import {
  CONTROL_PLANE_STATUS_CHECKING,
  CONTROL_PLANE_STATUS_HEALTHY,
  CONTROL_PLANE_STATUS_NOT_CHECKED,
} from '../TalosConstants';

const ControlPlanes = ({
  clusterControlPlanes,
  clusterInfo,
  fetchingClusterControlPlanes,
  toggleCollapse,
  clusterIdentifier,
  clusterMode,
  setIsOpenUpgradeModal,
  setEditControlPlaneModalIsOpen,
  controlPlanesUpdating,
  isUpgrading,
}) => {
  const [controlPlanesHealthState, setControlPlanesHealthState] = useState(null);

  useEffect(() => {
    if (clusterInfo.controlPlanesHealth) {
      setControlPlanesHealthState(clusterInfo.controlPlanesHealth);
      handleHealthInfo();
    }
  }, [clusterInfo]);

  const handleCheckStatus = () => {
    axios.get(`/api/user/kubernetes-talos/${clusterIdentifier}/health`).then(res => {
      if (res && res.status === 200) {
        const checkedControlPlanesHealthState = [];
        controlPlanesHealthState.forEach(cp => {
          checkedControlPlanesHealthState.push({
            ...cp,
            health: CONTROL_PLANE_STATUS_CHECKING,
          });
        });

        setControlPlanesHealthState(checkedControlPlanesHealthState);
      }
    });
  };

  const upgradeControlPlane = event => {
    event.preventDefault();
    setIsOpenUpgradeModal(true);
  };

  const handleHealthInfo = () => {
    if (controlPlanesHealthState && controlPlanesHealthState.length > 0) {
      let notCheckedCount = 0;
      let checkingCount = 0;
      let healthyCount = 0;

      controlPlanesHealthState.forEach(cpHealth => {
        if (cpHealth.health === CONTROL_PLANE_STATUS_NOT_CHECKED) {
          notCheckedCount += 1;
        }
        if (cpHealth.health === CONTROL_PLANE_STATUS_CHECKING) {
          checkingCount += 1;
        }
        if (cpHealth.health === CONTROL_PLANE_STATUS_HEALTHY) {
          healthyCount += 1;
        }
      });

      if (notCheckedCount > 0) {
        return (
          <div className="d-flex mr-1">
            <div>
              <i className="far mr-1 fa-times-circle" />
              Not checked
            </div>
          </div>
        );
      }
      if (healthyCount === controlPlanesHealthState.length) {
        return (
          <div className="d-flex mr-1">
            <strong className="text-success">
              <i className="far mr-1 fa-check-circle on" />
              Healthy
            </strong>
          </div>
        );
      }
      if (checkingCount === controlPlanesHealthState.length) {
        return (
          <div className="d-flex align-items-center mr-1">
            <div className="spinner-border spinner-border-sm text-primary mb-1 mx-2" role="status" />
            <span className="pb-1">checking...</span>
          </div>
        );
      }
      return (
        <div className="d-flex mr-1">
          <div className="text-danger">
            <i className="far mr-1 fa-check-circle off" />
            Unhealthy
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {fetchingClusterControlPlanes ? (
        <div className="pl-2 pr-2">
          <Skeleton count={10} height={30} />
        </div>
      ) : (
        <div key={`cp-pool-1`}>
          <div className="hierarchy px-4 py-2">
            <Button
              className="btn-expand text-primary border-0"
              color=""
              onClick={e => toggleCollapse(e)}
              data-open="false"
            >
              <i className="fas fa-chevron-circle-down" />
            </Button>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                Control Planes
                {controlPlanesUpdating ? (
                  <>
                    <div className="d-flex">Control planes updating resources ...</div>
                  </>
                ) : (
                  controlPlanesHealthState &&
                  clusterInfo.controlPlanesHealth &&
                  clusterInfo.controlPlanesHealth.length > 0 && (
                    <>
                      <div className="d-flex">
                        {handleHealthInfo()} (last updated:{' '}
                        {controlPlanesHealthState[0].lastHealthCheck
                          ? controlPlanesHealthState[0].lastHealthCheck
                          : 'never'}
                        )
                      </div>
                    </>
                  )
                )}
              </div>
              <div>
                {clusterMode === 'test' && (
                  <Button
                    color="light"
                    data-cy="control-planes-upgragde"
                    className="btn-sm y-2 px-3 mr-3"
                    style={{ transform: 'rotate(0deg)', minWidth: '92px' }}
                    onClick={e => upgradeControlPlane(e)}
                    disabled={isUpgrading || controlPlanesUpdating}
                  >
                    {isUpgrading && (
                      <div className="spinner-border spinner-border-sm text-primary mb-1 mr-2" role="status" />
                    )}
                    Upgrade
                  </Button>
                )}
                <Button
                  color="light"
                  data-cy="pools-and-nodes-alert-settings"
                  className="mr-3 py-2 px-3"
                  style={{ transform: 'rotate(0deg)' }}
                  disabled={controlPlanesUpdating}
                  onClick={() => {
                    setEditControlPlaneModalIsOpen(true);
                  }}
                >
                  <i className="fas fa-cog" />
                </Button>
                <Button
                  className="btn-sm"
                  color="primary"
                  onClick={handleCheckStatus}
                  style={{ transform: 'rotate(0deg)', minWidth: '92px' }}
                  data-cy="pools-and-nodes-add-service"
                  disabled={controlPlanesUpdating}
                >
                  <i className="fas fa-sync mr-2" />
                  Check
                </Button>
              </div>
            </div>
          </div>
          <div className="hierarchy-children pl-4">
            <div className="hierarchy-items">
              {clusterControlPlanes.map((controlPlane, index) => (
                <div
                  key={`${index}-${controlPlane['identifier']}`}
                  className="pr-3 pl-4 py-2 border-bottom d-flex justify-content-between align-items-center"
                >
                  {controlPlane['name']}
                  {clusterInfo.controlPlanesStatus &&
                  clusterInfo.controlPlanesStatus[index] === 'Updating resources' ? (
                    <div className="d-flex mr-2">
                      <div>
                        <i className="mr-2 fa fa-refresh rotating" style={{ fontSize: '10px' }} />
                        Updating resources
                      </div>
                    </div>
                  ) : (
                    controlPlanesHealthState &&
                    clusterInfo.controlPlanesHealth && (
                      <>
                        {controlPlanesHealthState[index].health === CONTROL_PLANE_STATUS_NOT_CHECKED ? (
                          <div className="d-flex mr-2">
                            <div>
                              <i className="far mr-1 fa-times-circle" />
                              Not checked
                            </div>
                          </div>
                        ) : controlPlanesHealthState[index].health === CONTROL_PLANE_STATUS_CHECKING ? (
                          <div className="d-flex align-items-center mr-2">
                            <div className="spinner-border spinner-border-sm text-primary mb-1 mx-2" role="status" />
                            <span className="mb-1">checking ...</span>
                          </div>
                        ) : controlPlanesHealthState[index].health === CONTROL_PLANE_STATUS_HEALTHY ? (
                          <div className="text-success mr-2">
                            <i className="far ml-2 mr-1 fa-check-circle on" />
                            Healthy
                          </div>
                        ) : (
                          <div className="text-danger mr-2">
                            <i className="far ml-2 mr-1 fa-check-circle off" />
                            Unhealthy
                          </div>
                        )}
                      </>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ControlPlanes;
