import React, { useState, useEffect, useContext, useReducer } from 'react';
import { withRouter } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import axios from '../../axiosInstance';
import { handleComposeMenu, handleConvertPermissions, setAuthInitialState } from '../Auth/utils';
import { AUTH_LOG_IN_HANDLER, UPDATE_SINGLE_PROPERTY } from '../Auth/constants';
import { updateToken } from '../../utils/auth';
import { PushNotificationContext } from './PushNotificationContext';
import { debounce } from 'lodash';
import { EchoContext } from './EchoContext';
import { UiContext } from './UiContext';

const AuthContext = React.createContext({});

const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_LOG_IN_HANDLER:
      return {
        ...state,
        ...action.authState,
      };
    case UPDATE_SINGLE_PROPERTY:
      return {
        ...state,
        [action.property]: action.value,
      };
    default:
      return state;
  }
};

const AuthProvider = props => {
  const initialState = setAuthInitialState();
  const [globalAuthState, dispatchAuthState] = useReducer(authReducer, initialState);
  const [tokenId, setTokenId] = useState(undefined);
  const [totpInfo, setTotpInfo] = useState({});
  const [twoFactorLoginProcess, setTwoFactorLoginProcess] = useState(false);
  const [needAcceptAgreement, setNeedAcceptAgreement] = useState(false);
  const [isListenerActivated, setIsListenerActivated] = useState(false);

  const { echo, createWsConnection, startWsListening } = useContext(EchoContext);
  const { changeDarkModeHandler } = useContext(UiContext);

  useEffect(() => {
    if (globalAuthState.isAuth && echo && globalAuthState.user && globalAuthState.user.id && !isListenerActivated) {
      startWsListening(globalAuthState.user);
      setIsListenerActivated(true);
    }
  }, [globalAuthState.isAuth, echo, globalAuthState.user]);

  const setUserInfo = () => {
    if (localStorage['token']) {
      axios
        .get('api/user/info')
        .then(res => {
          loginSuccessHandler(res.data, props, false, false, true, !!localStorage['root'], true, false);
          changeDarkModeHandler(res.data.user.settings.darkMode);
        })
        .catch(e => {
          console.warn(e.message);
        });
    }
  };

  useEffect(setUserInfo, []);

  useEffect(() => {
    if (globalAuthState.token && globalAuthState.token.length > 0) {
      localStorage.setItem('appState', JSON.stringify(globalAuthState));
      if (localStorage.getItem('token') === null || localStorage.getItem('root')) {
        localStorage.setItem('token', globalAuthState.token);
      }
    }
  }, [globalAuthState.token]);

  const { pushNotification } = useContext(PushNotificationContext);

  const login = debounce((data, innerProps, callback) => {
    axios
      .post('api/user/login', data)
      .then(res => {
        if (res && res.status === 200) {
          if (res.data.apiToken) {
            localStorage.setItem('token', res.data.apiToken);
          }
          if (res.data.status === 'sms') {
            setTwoFactor(res.data);
            innerProps.history.push('/login/code/sms');
          } else if (res.data.status === 'totp' || res.data.status === 'totp_with_sms') {
            setTotpInfo({
              status: res.data.status,
              secretExists: res.data.secretExists,
              email: res.data.email,
              requestId: res.data.requestId,
              secret: res.data.secret || null,
            });
            innerProps.history.push('/login/code/totp');
          } else if (res.data.status === 'need_agree') {
            setNeedAcceptAgreement(true);
          } else {
            loginSuccessHandler(res.data, props);
          }

          changeDarkModeHandler(res.data.user.settings.darkMode);

          localStorage.setItem('currentPage', 1);
        }
      })
      .then(() => callback && callback())
      .catch(e => console.warn(e));
  }, 200);

  const thirdPartyLogin = async (providerName, token) => {
    axios
      .post('api/user/social-login', {
        providerName,
        token,
        promocode: localStorage.getItem('promocode') || null,
      })
      .then(res => {
        if (res && res.status === 200) {
          localStorage.removeItem('promocode');
          if (res.data.apiToken) {
            localStorage.setItem('token', res.data.apiToken);
          }
          if (res.data.status === 'sms') {
            setTwoFactor(res.data);
            props.history.push('/login/code/sms');
          } else if (res.data.status === 'totp' || res.data.status === 'totp_with_sms') {
            setTotpInfo({
              status: res.data.status,
              secretExists: res.data.secretExists,
              email: res.data.email,
              requestId: res.data.requestId,
              secret: res.data.secret || null,
            });
            props.history.push('/login/code/totp');
          } else {
            loginSuccessHandler(res.data, props);
          }
          localStorage.setItem('currentPage', 1);
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 422) {
          pushNotification({
            data: {
              type: 'danger',
              name: err.response.data.error,
            },
            id: uuidv4(),
          });
        }
        window.history.pushState({}, document.title, '/login');
      });
  };

  const twoFactorLogin = (data, props, callback) => {
    const payload = {
      code: data.code,
    };

    if (tokenId) {
      payload.token_id = tokenId;
    }

    if (totpInfo) {
      payload.requestId = totpInfo.requestId;
    }

    payload.isRememberMe = localStorage.getItem('rememberMeCheckbox') === 'true';

    setTwoFactorLoginProcess(true);

    axios
      .post(`/api/user/code${data.type === 'totp' ? '/totp' : ''}`, payload)
      .then(res => {
        if (res && res.data) {
          if (res.data.token) {
            localStorage.setItem('token', res.data.token);
          }
          setTwoFactorLoginProcess(false);
          loginSuccessHandler(res.data, props);
          localStorage.setItem('currentPage', 1);
        }
      })
      .then(() => callback && callback())
      .catch(e => {
        console.warn(e.message);
        setTwoFactorLoginProcess(false);
        if (data.type !== 'totp') {
          props.history.push('/login');
        }
      });
  };

  const logout = () => {
    const authState = {
      isAuth: false,
      allowCreatingTrial: false,
      dashboardWidget: '',
      user: {},
      token: '',
      loggedInAsOtherUser: false,
      rootUser: {},
      menu: [],
      isRoot: false,
      tenant: '',
      demoTenant: false,
      onboardingCompleted: undefined,
      paidTenant: false,
      tenantType: '',
      parent: '',
      userPermissions: {},
      featureFlags: {},
    };
    axios.post('api/user/logout').then(() => {
      dispatchAuthState({ type: AUTH_LOG_IN_HANDLER, authState });
      localStorage.removeItem('token');
      localStorage.removeItem('appState');
    });
  };

  const updateDemoTenant = value => {
    dispatchAuthState({
      type: UPDATE_SINGLE_PROPERTY,
      property: 'demoTenant',
      value,
    });
    const appState = JSON.parse(localStorage.getItem('appState'));
    appState.demoTenant = value;
    localStorage.setItem('appState', JSON.stringify(appState));
  };

  const updatePaidTenant = value => {
    dispatchAuthState({
      type: UPDATE_SINGLE_PROPERTY,
      property: 'paidTenant',
      value,
    });
    const appState = JSON.parse(localStorage.getItem('appState'));
    appState.paidTenant = value;
    localStorage.setItem('appState', JSON.stringify(appState));
  };

  const onboardingDeactivate = () => {
    dispatchAuthState({
      type: UPDATE_SINGLE_PROPERTY,
      property: 'onboardingCompleted',
      value: true,
    });
    const appState = JSON.parse(localStorage.getItem('appState'));
    appState.onboardingCompleted = true;
    localStorage.setItem('appState', JSON.stringify(appState));
  };

  const loginAsOtherUser = (userId, tenantIdentifier) => {
    axios
      .post(`api/user/tenants/users/login`, { userId })
      .then(res => {
        if (res && res.data) {
          echo.disconnect();

          createWsConnection(res.data.user.apiToken);
          startWsListening(res.data.user);

          changeDarkModeHandler(res.data.user.settings.darkMode);
          const rootToken = globalAuthState.token.slice();
          const rootUser = { ...globalAuthState.user };

          localStorage.setItem(
            'root',
            JSON.stringify({
              rootToken,
              rootUser,
            })
          );
          const newMenu = handleComposeMenu(
            res.data.user.permissions,
            res.data.user.featureFlags !== undefined
              ? Object.fromEntries(res.data.user.featureFlags.map(el => [el, true]))
              : {},
            res.data.user.tenantType,
            res.data.user.acceptDocumentsDaysLeft && res.data.user.acceptDocumentsDaysLeft < 0,
            res.data.user.isXelonChild,
            process.env.REACT_APP_WHITELABELING === 'true'
          );
          const convertedPermissions = handleConvertPermissions(res.data.user.permissions);
          const authState = {
            allowCreatingTrial: res.data.user.allowCreatingTrial,
            loggedInAsOtherUser: true,
            token: res.data.user.apiToken,
            user: res.data.user,
            menu: newMenu,
            isRoot: checkIfRoot(res.data.user.roleNames),
            tenant: res.data.user.tenantIdentifier,
            demoTenant: res.data.user.demoTenant,
            customerType: res.data.user.customerType,
            onboardingCompleted: res.data.user.onboarding_completed,
            paidTenant: res.data.user.paidTenant,
            tenantType: res.data.user.tenantType,
            tenantStatus: res.data.user.tenant.status,
            parent: res.data.user.tenant.parent,
            userPermissions: convertedPermissions,
            featureFlags:
              res.data.user.featureFlags !== undefined
                ? Object.fromEntries(res.data.user.featureFlags.map(el => [el, true]))
                : {},
          };
          dispatchAuthState({ type: AUTH_LOG_IN_HANDLER, authState });
          props.history.push('');
          localStorage.setItem('currentPage', 1);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const logoutAsOtherUser = () => {
    const root = JSON.parse(localStorage['root']);
    const newMenu = handleComposeMenu(
      root.rootUser.permissions,
      root.rootUser.featureFlags !== undefined
        ? Object.fromEntries(root.rootUser.featureFlags.map(el => [el, true]))
        : {},
      root.rootUser.tenantType,
      root.rootUser.acceptDocumentsDaysLeft && root.rootUser.acceptDocumentsDaysLeft < 0,
      root.rootUser.isXelonChild,
      process.env.REACT_APP_WHITELABELING === 'true'
    );
    const convertedPermissions = handleConvertPermissions(root.rootUser.permissions);
    const authState = {
      allowCreatingTrial: root.rootUser.allowCreatingTrial,
      loggedInAsOtherUser: false,
      token: root.rootToken,
      user: root.rootUser,
      menu: newMenu,
      isRoot: checkIfRoot(root.rootUser.roleNames),
      tenant: root.rootUser.tenantIdentifier,
      demoTenant: root.rootUser.demoTenant,
      customerType: root.rootUser.customerType,
      onboardingCompleted: root.rootUser.onboarding_completed,
      paidTenant: root.rootUser.paidTenant,
      tenantType: root.rootUser.tenantType,
      tenantStatus: root.rootUser.status,
      parent: root.rootUser.parent,
      userPermissions: convertedPermissions,
      featureFlags:
        root.rootUser.featureFlags !== undefined
          ? Object.fromEntries(root.rootUser.featureFlags.map(el => [el, true]))
          : {},
    };

    echo.disconnect();

    createWsConnection(root.rootToken);
    startWsListening(root.rootUser);

    if (root.rootUser.whiteLabels) {
      authState.whiteLabels = root.rootUser.whiteLabels;
    }

    localStorage.setItem('appState', JSON.stringify(authState));

    changeDarkModeHandler(root.rootUser.settings.darkMode);
    updateToken(root.rootToken);
    dispatchAuthState({ type: AUTH_LOG_IN_HANDLER, authState });
    localStorage.removeItem('root');
    props.history.push('');
  };

  const setTwoFactor = data => {
    setTokenId(data.token_id);
  };

  const loginSuccessHandler = (
    resData,
    props = null,
    route = '/',
    navigationObject,
    noRedirect,
    loggedInAsOtherUser,
    fromComponentDidMount,
    isSetToken = true
  ) => {
    const newMenu = handleComposeMenu(
      resData.user.permissions,
      resData.user.featureFlags !== undefined
        ? Object.fromEntries(resData.user.featureFlags.map(el => [el, true]))
        : {},
      resData.user.tenantType,
      resData.user.acceptDocumentsDaysLeft && resData.user.acceptDocumentsDaysLeft < 0,
      resData.user.isXelonChild,
      process.env.REACT_APP_WHITELABELING === 'true'
    );
    const convertedPermissions = handleConvertPermissions(resData.user.permissions);
    // const {
    //   address,
    //   address2,
    //   city,
    //   postcode,
    //   country,
    //   vat,
    // } = resData.user.tenant;
    const authState = {
      isAuth: true,
      allowCreatingTrial: resData.user.allowCreatingTrial,
      user: resData.user,
      menu: newMenu,
      loggedInAsOtherUser: loggedInAsOtherUser && true,
      ...(isSetToken && { token: resData.user.apiToken }),
      ...(!fromComponentDidMount && { dashboardWidget: 'recently_accessed' }),
      isRoot: checkIfRoot(resData.user.roleNames),
      tenant: resData.user.tenantIdentifier,
      demoTenant: resData.user.demoTenant,
      paidTenant: resData.user.paidTenant,
      customerType: resData.user.customerType,
      onboardingCompleted: resData.user.onboarding_completed,
      tenantType: resData.user.tenantType,
      tenantStatus: resData.user.tenant.status,
      parent: resData.user.tenant.parent,
      userPermissions: convertedPermissions,
      featureFlags:
        resData.user.featureFlags !== undefined
          ? Object.fromEntries(resData.user.featureFlags.map(el => [el, true]))
          : {},
      whiteLabels: resData.user.whiteLabels,
    };
    dispatchAuthState({ type: AUTH_LOG_IN_HANDLER, authState });
    !loggedInAsOtherUser && localStorage.removeItem('root');
    !noRedirect &&
      handleRedirect(
        props,
        navigationObject
          ? navigationObject
          : resData.user.acceptDocumentsDaysLeft && resData.user.acceptDocumentsDaysLeft < 0
          ? '/documents'
          : route
      );
    localStorage.setItem('appState', JSON.stringify(authState));
  };

  const updateDashboardWidget = value => {
    dispatchAuthState({
      type: UPDATE_SINGLE_PROPERTY,
      property: 'dashboardWidget',
      value,
    });
    const appState = JSON.parse(localStorage.getItem('appState'));
    appState.dashboardWidget = value;
    localStorage.setItem('appState', JSON.stringify(appState));
  };

  const handleRedirect = (props, navigationItem) => {
    props.history.push(navigationItem);
  };

  const checkIfRoot = roles => !roles.every(el => el !== 'RootAdmin');

  const setUser = changedUser => {
    if (changedUser) {
      const userCopy = { ...globalAuthState.user };
      Object.keys(userCopy).forEach(el => {
        Object.keys(changedUser).forEach(elem => {
          if (el === elem) {
            userCopy[el] = changedUser[elem];
          }
        });
      });
      dispatchAuthState({
        type: UPDATE_SINGLE_PROPERTY,
        property: 'user',
        value: userCopy,
      });
      const appState = JSON.parse(localStorage.getItem('appState'));
      appState.user = userCopy;
      localStorage.setItem('appState', JSON.stringify(appState));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        allowCreatingTrial: globalAuthState.allowCreatingTrial,
        isAuth: globalAuthState.isAuth,
        menu: globalAuthState.menu,
        user: globalAuthState.user,
        userPermissions: globalAuthState.userPermissions,
        featureFlags: globalAuthState.featureFlags,
        token: globalAuthState.token,
        loggedInAsOtherUser: globalAuthState.loggedInAsOtherUser,
        isRoot: globalAuthState.isRoot,
        tenant: globalAuthState.tenant,
        demoTenant: globalAuthState.demoTenant,
        paidTenant: globalAuthState.paidTenant,
        customerType: globalAuthState.customerType,
        onboardingCompleted: globalAuthState.onboardingCompleted,
        tenantType: globalAuthState.tenantType,
        tenantStatus: globalAuthState.tenantStatus,
        passwordShouldBeChanged: globalAuthState.user.passwordShouldBeChanged,
        totpInfo,
        twoFactorLoginProcess,
        needAcceptAgreement,
        login,
        logout,
        twoFactorLogin,
        loginAsOtherUser,
        thirdPartyLogin,
        logoutAsOtherUser,
        loginSuccessHandler,
        handleRedirect,
        setUser,
        setUserInfo,
        setDemoTenant: updateDemoTenant,
        setPaidTenant: updatePaidTenant,
        setDashboardWidget: updateDashboardWidget,
        onboardingDeactivate,
        setTwoFactor,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

const AuthConsumer = AuthContext.Consumer;

export default withRouter(AuthProvider);
export { AuthConsumer, AuthContext };
