import React, { useEffect, useState, createContext, useContext } from 'react';
import * as PropTypes from 'prop-types';
import Echo from 'laravel-echo';
import { withAuthContext } from '../hoc/withAuthContext';
import { v4 as uuidv4 } from 'uuid';
import { PushNotificationContext } from './PushNotificationContext';
require('pusher-js');

const createEchoInstance = token =>
  new Echo({
    authEndpoint: process.env.REACT_APP_WEBSITE_URL + '/api/broadcasting/auth',
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: 'mt1',
    wsHost:
      process.env.REACT_APP_PUSHER_URL.replace(/https?:\/\//i, '') ||
      process.env.REACT_APP_WEBSITE_URL.replace(/https?:\/\//i, ''),
    wsPort: 6001,
    auth: {
      headers: {
        Authorization: token,
        Accept: 'application/json',
      },
    },
    enabledTransports: ['ws'],
  });

const EchoContext = createContext({});

const EchoProvider = ({ children }) => {
  const [echoInstance, setEchoInstance] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const { pushNotification } = useContext(PushNotificationContext);

  useEffect(() => {
    const checkToken = () => {
      const newToken = localStorage.getItem('token');
      if (newToken && newToken !== token) {
        setToken(newToken);
      }
    };

    const intervalId = setInterval(checkToken, 1000);

    return () => clearInterval(intervalId);
  }, [token]);

  useEffect(() => {
    if (token && !echoInstance) {
      createWsConnection(token);
    }
    return () => {
      if (echoInstance) {
        echoInstance.disconnect();
      }
    };
  }, [token]);

  const createWsConnection = token => {
    const newEchoInstance = createEchoInstance('Bearer ' + token);
    setEchoInstance(newEchoInstance);
  };

  const startWsListening = user => {
    if (!echoInstance) {
      return;
    }

    const wsWithNotifications = [
      {
        chanel: `users.${user.id}`,
        events: ['.talosHealthCheckFinished', '.K8sPoolChanged', '.deviceBackupChanged'],
      },
      {
        chanel: `tenant.${user.tenant.tenant_identifier}`,
        events: ['.totp_enabled'],
      },
    ];

    wsWithNotifications.forEach(ws => {
      ws.events.forEach(event => {
        echoInstance.private(ws.chanel).listen(event, res => {
          if (res.message) {
            pushNotification({
              data: {
                type: 'success',
                name: res.message,
              },
              id: uuidv4(),
              timeout: 50000,
            });
          }
        });
      });
    });
  };

  return (
    <EchoContext.Provider
      value={{
        echo: echoInstance,
        createWsConnection,
        startWsListening,
      }}
    >
      {children}
    </EchoContext.Provider>
  );
};

EchoProvider.propTypes = { children: PropTypes.node };

export default withAuthContext(EchoProvider);
export { EchoContext };
